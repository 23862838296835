export const imagecontainerAnime1 = {
  hidden: {
    height: 550,
  },
  show: {
    height: 0,
    transition: {
      duration: 0.9,
    },
  },
};
export const imagecontainerAnime2 = {
  hidden: {
    height: 550,
  },
  show: {
    height: 0,
    transition: {
      duration: 0.9,
      delay: 0.9,
    },
  },
};
export const imagecontainerAnime3 = {
  hidden: {
    height: 550,
  },
  show: {
    height: 0,
    scale: 3,
    transition: {
      duration: 0.7,
      delay: 1.8,
    },
  },
};

export const textAnime = {
  hidden: {
    y: -10,
    opacity: 1,
  },
  show: {
    y: 70,
    opacity: 0,
    transition: {
      ease: "easeOut",
      duration: 0.7,
      delay: 2,
    },
  },
};




export const mainWrapper = {
    hidden:{
        width: "100%",
        // display: "flex",
    },
    show:{
        width:"0%",
        // display:"none",
        transition: {
            ease: "easeOut",
            duration: 1,
            delay: 2.9,
          },

    }
}
