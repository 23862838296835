import { useState, useRef } from "react";
import Navbar from "../Components/Navbar";
import Menu from "../Components/Menu";
import styled from "styled-components";

// import images
import Slide1 from "../Resources/slide-1.png";
import Slide2 from "../Resources/slide-2.png";
import Slide3 from "../Resources/slide-3.png";
import Slide4 from "../Resources/slide-4.png";
import Slide5 from "../Resources/slide-5.png";
import Slide6 from "../Resources/slide-6.png";

function Explore() {
  const [control, setControl] = useState("none");
  const gallery = useRef();

  return (
    <ExploreWrapper>
      <Navbar setcontrol={setControl} />
      <Menu control={control} setcontrol={setControl} />
      <div className="explore-wrapper">
        <div className="explore-container">
          <p>
            This page contains pictures related to Excel Oladeji and his work,
            respective owners of each piece of work have been credited.
          </p>
          <h1>
            Afro-urban fashion styles that showcase modern fashion in a clean
            and minimalist manner pique my interest.
          </h1>
          <div className="physical-features">
            <h3>Physical features</h3>
            <p>
              Age:<span>7 years old</span>
            </p>
            <p>
              Gender:<span>Male</span>
            </p>
            <p>
              Shoe size:<span> 32/33</span>
            </p>
            <p>
              Height:<span> 4.3 Ft</span>
            </p>
          </div>
        </div>
        <div className="explore-container">
          <div className="gallery" ref={gallery}>
            <div>
              <span>
                <img src={Slide1} alt="slide1" />
              </span>
              <span>
                <img src={Slide2} alt="slide2" />
              </span>
              <span>
                <img src={Slide3} alt="slide3" />
              </span>
              <span>
                <img src={Slide4} alt="slide4" />
              </span>
              <span>
                <img src={Slide5} alt="slide5" />
              </span>
              <span>
                <img src={Slide6} alt="slide6" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </ExploreWrapper>
  );
}

const ExploreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  .explore-wrapper {
    display: flex;
    padding: 70px;
    width: 100%;
    .explore-container {
      :nth-child(1) {
        width: 35%;
      }
      p {
        font-size: 16px;
        color: #373636;
        line-height: 26px;
      }
      h1 {
        margin-top: 10px;
        font-size: 30px;
      }
      .physical-features {
        margin-top: 20px;
        h3 {
          font-family: lato;
        }
        p {
          font-weight: bold;
          margin-top: 10px;
          border-bottom: 1px solid black;
          padding-bottom: 5px;
          width: 150px;
          font-size: 16px;
          span {
            font-weight: 300;
            margin-left: 10px;
          }
        }
      }
      .gallery {
        width: 800px;
        height: 100%;
        /* background-color: aquamarine; */
        display: flex;
        margin-left: 50px;
        overflow-x: scroll;
        ::-webkit-scrollbar {
          display: none;
        }
        div {
          width: 100%;
          /* display: grid;
          grid-template-columns: auto auto auto;
          grid-gap: 20px;
          padding: 10px;
          float: left;
          flex: none; */
          display: flex;
          flex-wrap: nowrap;
        }
        span {
          margin-left: 20px;
          :nth-child(1) {
            margin-left: 0;
          }
        }
        img {
          width: 450px;
        }
      }
    }
  }
`;

export default Explore;
