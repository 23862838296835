import styled from "styled-components";
import CtaImg from "../Resources/cta-img.png";
import BigText from "../Resources/Big-text.png";
import TxtMobile from "../Resources/Txt-mobile.png";
import MobileImg from "../Resources/mobile-img.png";

import { NavLink } from "react-router-dom";

function CTA() {
  return (
    <CtaWrapper>
      <div className="container">
        <h1 className="cta-heading">
          Excel Oladeji is a seven year old fashion model based in Ibadan,
          Nigeria.
        </h1>
        <div className="container-extension">
          <svg
            width="61"
            height="92"
            viewBox="0 0 61 92"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M59.995 56.4005C59.9401 55.851 59.45 55.45 58.9005 55.505L49.9452 56.4005C49.3956 56.4554 48.9947 56.9455 49.0496 57.495C49.1046 58.0446 49.5946 58.4455 50.1442 58.3906L58.1045 57.5945L58.9005 65.5548C58.9554 66.1044 59.4455 66.5053 59.995 66.4504C60.5446 66.3954 60.9455 65.9054 60.8906 65.3558L59.995 56.4005ZM15.5453 0.702484C9.95558 18.6396 3.23192 44.5654 1.26757 63.9869C0.775997 68.8471 0.579845 73.3236 0.779658 77.1797C0.978649 81.0199 1.5735 84.3217 2.71631 86.7849C3.8716 89.275 5.64394 91.0088 8.18515 91.4268C10.6506 91.8323 13.5928 90.9514 17.022 88.853L15.978 87.147C12.7072 89.1486 10.2754 89.7437 8.50976 89.4533C6.81985 89.1753 5.50392 88.0411 4.53056 85.9432C3.54472 83.8184 2.97056 80.8121 2.77698 77.0762C2.58421 73.3561 2.77191 68.9883 3.25742 64.1881C5.20141 44.9679 11.8777 19.1938 17.4547 1.29752L15.5453 0.702484ZM17.022 88.853C43.8424 72.4405 56.6628 60.9357 59.774 57.1332L58.226 55.8668C55.3372 59.3976 42.7576 70.7595 15.978 87.147L17.022 88.853Z"
              fill="black"
            />
          </svg>

          <div className="container-text">
            <p>
              His main area of interest is Afro-Urban fashion, particularly when
              it comes to walking attire. He loves to experiment with various
              color combinations that resonate with the contemporary lifestyle
              and pop culture.
            </p>

            <NavLink to="/Explore">Explore my profile</NavLink>
          </div>
        </div>
        <img src={BigText} alt="text-img" className="big-text" />
      </div>
      <div className="container">
        <img src={CtaImg} alt="cta-img" className="cta-img" />
      </div>

      {/* mobile elemnets */}
      <div className="container-mobile">
        <h1>
          Excel Oladeji is a Seven year old fashion model based in Ibadan,
          Nigeria.
        </h1>
        <img
          src={TxtMobile}
          className="mobile-txt"
          alt="fashion model excel oladeji"
        />
        <p>
          His main area of interest is Afro-Urban fashion, particularly when it
          comes to walking attire. He loves to experiment with various color
          combinations that resonate with the contemporary lifestyle and pop
          culture.
        </p>
        <NavLink to="/Explore">Explore my profile</NavLink>
      </div>
      <img className="mobile-img" src={MobileImg} alt="mobile bottom img" />
    </CtaWrapper>
  );
}

const CtaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 50px 70px 20px 70px;
  width: 100%;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    /* background-color: green; */
    padding: 0px;
  }
  .container {
    @media only screen and (max-width: 600px) {
      display: none;
    }
    .cta-heading {
      font-size: 36px;
      width: 500px;
    }
    .container-extension {
      display: flex;
      flex-direction: row;
      margin-top: 30px;
      .container-text {
        width: 450px;
        margin-left: 30px;
        p {
          font-size: 18px;
          line-height: 30px;
          color: #373636;
          margin-bottom: 10px;
        }
        a {
          font-size: 20px;
          font-weight: bolder;
          color: black;
          text-decoration: none;
          padding-bottom: 2px;
          border-bottom: 2px solid black;
        }
      }
    }
    .big-text {
      position: absolute;
    }
    .cta-img {
      margin-left: 100px;
    }
  }
  .container-mobile {
    @media only screen and (min-width: 600px) {
      display: none;
    }
    padding: 20px;
    padding-top: 50px;
    h1 {
      font-size: 24px;
      line-height: 30px;
    }
    .mobile-txt {
      width: 90%;
      margin-top: 10px;
    }
    p {
      font-size: 16px;
      line-height: 25px;
      margin-top: 30px;
      color: #373636;
      font-weight: 400;
    }
    a {
      padding-bottom: 5px;
      text-decoration: none;
      border-bottom: 1px solid black;
      font-weight: bold;
      font-size: 20px;
      color: black;
      display: block;
      margin-top: 10px;
      width: fit-content;
    }
  }
  .mobile-img {
    @media only screen and (min-width: 600px) {
      display: none;
    }
  }
`;
export default CTA;
