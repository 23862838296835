import Hero from "../Components/Hero";
import Preloader from "../Components/Preloader";

function Home() {
  return (
    <>
      <Preloader />
      <Hero />
    </>
  );
}

export default Home;
