import styled from "styled-components";
import { NavLink } from "react-router-dom";

function Navbar({ setcontrol }) {
  return (
    <NavWrapper>
      <NavLink to="/">
        <h3>Profile</h3>
      </NavLink>

      <h3
        className="menu-toggle"
        onClick={() => {
          setcontrol("flex");
        }}
      >
        Menu
      </h3>
    </NavWrapper>
  );
}

const NavWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 70px 0 70px;
  height: fit-content;
  @media only screen and (max-width: 600px) {
    padding: 20px 20px 0 20px;
  }
  a{
    color: black;
    text-decoration: none;
  }
  h3 {
    font-family: "lato";
    font-size: 20px;
    @media only screen and (max-width: 600px) {
      font-size: 18px;
    }
  }
  .menu-toggle {
    cursor: pointer;
  }
`;

export default Navbar;
