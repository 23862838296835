import Menu from "./Menu";
import Navbar from "./Navbar";
import { useState } from "react";
import CTA from "./Cta";
import styled from "styled-components";

function Hero() {
  // states
  const [control, setControl] = useState("none");
  return (
    <HeroWrapper>
      <Navbar setcontrol={setControl} />
      <Menu control={control} setcontrol={setControl} />
      <CTA/>
    </HeroWrapper>
  );
}

const HeroWrapper = styled.div`
    background-color: #f2f2f2;
    height: fit-content;
    /* overflow-x: hidden; */
`
export default Hero;
