import styled from "styled-components";

// from resource
import image1 from "../Resources/image-1.png";
import image2 from "../Resources/image-2.png";
import image3 from "../Resources/image-3.png";

// animation variants
import { motion } from "framer-motion";
import {
  imagecontainerAnime1,
  imagecontainerAnime2,
  imagecontainerAnime3,
  mainWrapper,
  textAnime,
} from "../Animations/PreloaderVariant";

function Preloader() {
  return (
    <PreloaderContainer
      variants={mainWrapper}
      initial={"hidden"}
      animate={"show"}
    >
      <motion.div className="image-wrapper">
        <motion.div className="image-container">
          <motion.img
            variants={imagecontainerAnime3}
            initial={"hidden"}
            animate={"show"}
            src={image3}
            alt="firstimage"
          />
        </motion.div>
        <motion.div className="image-container">
          <motion.img
            variants={imagecontainerAnime2}
            initial={"hidden"}
            animate={"show"}
            src={image2}
            alt="secondimage"
          />
        </motion.div>
        <motion.div className="image-container">
          <motion.img
            variants={imagecontainerAnime1}
            initial={"hidden"}
            animate={"show"}
            src={image1}
            alt="lastimage"
          />
        </motion.div>
      </motion.div>
      <motion.h2
        variants={textAnime}
        initial={"hidden"}
        animate={"show"}
        className="preloader-title"
      >
        Excel Oladeji
      </motion.h2>
    </PreloaderContainer>
  );
}

// Preloader styles
const PreloaderContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: #111111;
  z-index: 30;
  .image-wrapper {
    width: 450px;
    overflow: hidden;
    align-self: center;
    justify-self: center;
    margin-top: 70px;
    @media only screen and (max-width: 600px) {
      width: 90%;
      height: 75%;
      display: flex;
      align-items: center;
      margin-top: 50px;
      justify-content: center;
    }
    .image-container {
      width: 450px;
      height: 550px;
      position: fixed;
      overflow: hidden;
      @media only screen and (max-width: 600px) {
        width: 315px;
        height: 355px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .preloader-title {
    color: white;
    position: absolute;
    bottom: 5%;
    font-size: 40px;
    width: 180px;
    font-family: "lato";
    margin-left: 100px;
    @media only screen and (max-width: 600px) {
      font-size: 28px;

      bottom: 20%;
      margin-left: 10%;
    }
  }
`;

export default Preloader;
