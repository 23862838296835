import styled from "styled-components";
import { NavLink } from "react-router-dom";

function Menu({ control, setcontrol }) {
  return (
    <MenuWrapper inputColor="red" displayControl={control}>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        id="close-button"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => {
          setcontrol("none");
        }}
      >
        <circle cx="18" cy="18" r="18" fill="white" />
        <rect
          x="8.30768"
          y="16.6154"
          width="19.3846"
          height="2.76923"
          fill="black"
        />
      </svg>
      <div className="menu-item special">
        <h4>01</h4>
        {/* <a href="/about"></a> */}
        <NavLink to="/about">About me</NavLink>
      </div>
      <div className="menu-item">
        <h4>02</h4>
        <a href="https://wa.me/message/AOVB6N4I7PUXN1">Contact me</a>
      </div>
      <div className="menu-item">
        <h4>03</h4>
        <a href="https://www.tiktok.com/@cute_excel?_t=8b6Lpf8WoJ3&_r=1">Vlog</a>
      </div>

      <div className="social-wrapper">
        <h1>Socials</h1>
        <div className="social-container">
          <a href="https://www.facebook.com/profile.php?id=100064322160311&mibextid=LQQJ4d">
            Facebook
          </a>
          <a href="https://www.tiktok.com/@cute_excel?_t=8b6Lpf8WoJ3&_r=1">
            Tiktok
          </a>
        </div>
        <div className="social-container">
          <a href="https://www.instagram.com/cute_excel">Instagram</a>
          <a href="https://www.youtube.com/@cuteexcellio">Youtube</a>
        </div>
      </div>
    </MenuWrapper>
  );
}

const MenuWrapper = styled.div`
  background-color: #111111;
  width: 30%;
  height: 100vh;
  position: fixed;
  display: flex;
  display: ${(props) => props.displayControl};
  top: 0;
  right: 0;
  flex-direction: column;
  padding: 50px 50px 20px 50px;
  color: white;
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 50px 30px 20px 30px;
  }
  #close-button {
    align-self: flex-end;
    cursor: pointer;
  }

  .menu-item {
    display: flex;
    margin-top: 20px;
    h4 {
      font-size: 20px;
      @media only screen and (max-width: 600px) {
        font-size: 16px;
      }
    }
    a {
      color: white;
      text-decoration: none;
      font-weight: bold;
      font-size: 45px;
      margin-left: 20px;
      @media only screen and (max-width: 600px) {
        font-size: 32px;
      }
    }
  }
  .special {
    margin-top: 100px;
  }

  .social-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    h1 {
      font-size: 24px;
    }
    .social-container {
      display: flex;
      margin-top: 20px;
      a {
        font-size: 25px;
        color: white;
        text-decoration: none;
        font-weight: 300;
        color: #d8d8d8;
        @media only screen and (max-width: 600px) {
          font-size: 20px;
        }
        :last-child {
          margin-left: 30px;
        }
      }
    }
  }
`;

export default Menu;
