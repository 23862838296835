import { useState } from "react";
import Navbar from "../Components/Navbar";
import Menu from "../Components/Menu";
import AboutImg from "../Resources/about-img.png";
import styled from "styled-components";

function About() {
  const [control, setControl] = useState("none");
  return (
    <AboutWrapper>
      <Navbar setcontrol={setControl} />
      <Menu control={control} setcontrol={setControl} />
      <div className="content-wrapper">
        <div className="content-container">
          <img src={AboutImg} alt="" />
        </div>
        <div className="content-container">
          <h1>
            My name is Excel Oladeji, and I am a 7-year-old child model with
            four years of modeling experience.
          </h1>
          <p>
            I've modeled for a variety of brands over the years, including
            lifestyle, entertainment, and fashion. I've also participated in a
            number of modeling competitions, including the Peak Milk, Dano Milk,
            Nigeria, Africa Fashion Kids Competition, where I placed second and
            was featured in four different newspapers (the Daily Sun, Tribune,
            etc.). I am currently the school's prince (Icast International
            School, Ibadan). I won the Fashion Finest Africa (FFA) People's
            Catwalk 2022 competition in Lagos. In Lagos, I was also crowned Mr.
            Kids Are Talented 2022.
          </p>
        </div>
      </div>
    </AboutWrapper>
  );
}

const AboutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e1e1e1;
  height: 100vh;
  @media only screen and (max-width: 600px) {
    height: fit-content;
  }

  .content-wrapper {
    display: flex;
    width: 70%;
    align-self: center;
    margin-top: 50px;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      width: 80%;
    }
    .content-container {
      h1 {
        @media only screen and (max-width: 600px) {
            font-size: 24px;
        }
      }
      p {
        font-size: 18px;
        margin-top: 20px;
        line-height: 32px;
        font-weight: 300;
        color: #373636;
      }
      img {
        @media only screen and (max-width: 600px) {
          width: 100%;
        }
      }
      :nth-child(2) {
        padding-left: 50px;
        @media only screen and (max-width: 600px) {
            padding: 0px;
            padding-bottom: 30px;
            margin-top: 20px;
        }
      }
    }
  }
`;
export default About;
